import React from "react";
import styles from "./HomeBody.module.css";

const HomeBody = ({ body }) => (
  <div className={styles.el}>
    <div
      className={styles.body}
      dangerouslySetInnerHTML={{
        __html: body.childMarkdownRemark.html,
      }}
    />
  </div>
);

export default HomeBody;
