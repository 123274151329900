import { Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import HomeBody from "./HomeBody";
import HomeTestimonials from "./HomeTestimonials";
import styles from "./index.module.css";

const Home = props => (
  <>
    <Link className={styles.link} to="/portfolio/">
      <div className={styles.el}>
        <div className={styles.container}>
          <h1 className={styles.heading}>
            {props.title}
            <div className={styles.cta}>View our work</div>
          </h1>
          <div className={styles.images}>
            <div className={`${styles.image} ${styles.active}`}>
              <Img fluid={props.images[0].fluid} />
            </div>
          </div>
        </div>
      </div>
    </Link>
    <HomeTestimonials />
    {/* <Divider /> */}
    <HomeBody body={props.body} />
  </>
);

export default Home;
