import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

import Home from "../components/Home";
import Layout from "../components/Layout";
import SEO from "../components/Seo";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query HomeQuery {
      allContentfulHome {
        edges {
          node {
            images {
              fluid(maxWidth: 2500, quality: 100) {
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
            metaDescription
            metaTitle
            title
            body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `);
  const dataNode = data.allContentfulHome.edges[0].node;

  return (
    <Layout>
      <SEO
        isHome
        metaDescription={dataNode.metaDescription}
        title={dataNode.metaTitle}
      />
      <Helmet
        script={[
          {
            type: "application/ld+json",
            innerHTML: `{
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Glen Fries Associates Architects",
              "url": "https://www.glenfries.com/",
              "telephone": "609-924-8700",
              "foundingDate": "1981",
              "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "505 Mercer Road",
                  "addressLocality": "Princeton",
                  "addressRegion": "NJ",
                  "postalCode": "08540",
                  "addressCountry": {
                      "@type": "Country",
                      "name": "USA"
                  }
              },
              "sameAs": [ 
                  "https://www.instagram.com/glenfriesarchitects/"
              ]
            }`,
          },
        ]}
      />
      <Home
        body={dataNode.body}
        images={dataNode.images}
        title={dataNode.title}
      />
    </Layout>
  );
};
export default IndexPage;
