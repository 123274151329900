import React from "react";
import Badge01 from "../../images/badge-01.png";
import Badge02 from "../../images/badge-02.png";
import Stars from "../../images/stars.png";
import styles from "./HomeTestimonials.module.css";

const HomeTestimonials = () => (
  <div className={styles.el}>
    <div className={styles.container}>
      <div className={styles.houzz}>
        <a
          className={styles.link}
          href="https://www.houzz.com/professionals/architects-and-building-designers/glen-fries-associates-pfvwus-pf~277497443"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src={Badge01}
            height="54"
            width="54"
            alt="Best of Houzz 2017 service"
          />
        </a>
        <a
          className={styles.link}
          href="https://www.houzz.com/professionals/architects-and-building-designers/glen-fries-associates-pfvwus-pf~277497443"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src={Badge02}
            height="54"
            width="54"
            alt="Best of Houzz 2017 service"
          />
        </a>
      </div>
      <div className={styles.text}>What customers say about us</div>
      <div className={styles.divider} />
      <div className={styles.quote}>
        <img
          className={styles.quoteStars}
          src={Stars}
          height="16"
          alt="Houzz stars"
        />
        <p className={styles.quoteText}>
          Glen is one of the most talented architects in Princeton, which is a
          town with many many architects. Glen has experience with many large
          and small projects in town, yet has also worked with many clients
          around the county, including New York City. This gives him a broad
          prospective of design and experience. I wouldn't hesitate to recommend
          him for a job. He's one the most creative and inspired architects that
          I have run across in my career as a real estate agent.
        </p>
        <div className={styles.quoteSource}>- Weichert Realtors</div>
      </div>
    </div>
  </div>
);

export default HomeTestimonials;
